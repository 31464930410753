import styled, { /*css*/ } from "styled-components";

const TabsWrapper = styled.div`
  width: 100%;
  display:flex;
  background: ${({background}) => background ? "transparent" : 'white'};
  padding: ${({padding}) => padding ? padding : '15px'};
  margin: ${({margin}) => margin ? margin : '0'};
  align-items:center;
`;

const TabsStyle = styled.div`
  padding: ${({padding}) => padding ? padding : '12px'};
  width: auto;
  display:flex;
  background: ${({active}) => active ? "rgb(242, 247, 249)" : 'transparent'};
  color: ${({active}) => active ? "rgb(0, 92, 135)" : 'rgb(102, 157, 183)'};
  font-family: ${({active}) => active ? "Rubik-Medium" : 'Rubik-Regular'};
  font-size: ${({active}) => active ? "16px" : '16px'};
  line-height: 19px;
  margin: ${({margin}) => margin ? margin : '0 25px 0 0'};
  justify-content:center;
  align-items:center;
  justify-content:center;
  border-radius:3px;
  cursor:pointer;
  border-radius:6px;
  max-height:37px;
`;

const ToolTip = styled.div`
display:flex;
margin: ${({margin}) => margin ? margin : '0 35px 0 0'};
width: ${({width}) => width ? width : '60px'};
height: ${({height}) => height ? height : '60px'};
cursor:pointer;
}
>div{
  width:40px;
  height:40px;
  display:flex;
  margin:auto;
  align-items: center;
  justify-content: center;
  background: ${({active, activeValue, transparent}) =>transparent?'none': activeValue?activeValue: active ? "#005C87" : '#f2f7f9'};
  border-radius:6px;
  padding-right:${({padding}) => padding ? "5px" : ''};
  >img{
    width:24px;
    height:24px;
  }
  >svg{
    fill-opacity:  ${({activeOpacity}) =>activeOpacity?"0.6" : ''};
    >path{
      fill: ${({ fill }) => fill};
      fill-opacity:  ${({activeOpacity}) =>activeOpacity?"0.6" : ''};
    }
    >svg{
      fill-opacity:  ${({activeOpacity}) =>activeOpacity?"0.6" : ''};
      >path{
        fill: ${({ fill }) => fill};
      }
      >g{
        >g{
          fill: ${({ fill }) => fill};
        }
        >path{
          fill: ${({ fill, active }) => active?"white": fill};
        }
      }
    }
  }
}
`;

const StyledSvg = styled.svg`
  height: ${({height}) => height ? height : '30px'};
  width: ${({width}) => width ? width : '30px'};
  float: left;
  fill: ${({ showCompany, show }) => show?show:showCompany ? 'transparent' : '#619ab5'};
  padding: ${({ padding }) => padding ? padding : '2px 0px 0px'};
  margin: ${({marginTop}) => marginTop && marginTop };
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'}
  pointer-events: none;
  >svg{
    >path{
      fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#619ab5'};
    }
    >g{
      >path{
        fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#619ab5'};
      }
      >g{
        fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#619ab5'};
      }
    }
  }
`;

const BuddyTab = styled.div`
display: flex;
min-width: ${({width}) => width ? "auto" : '114px'};
height: 40px;
padding: ${({padding}) => padding ? "10px 15px" : ''};
background: ${({active}) => active ? '#007AB1' : 'white'};
border-radius: 6px;
font-family: ${({active}) => active ? 'Rubik-Medium' : 'Rubik'};
font-size: 16px;
font-weight: 500;
line-height: 20px;
color: ${({active}) => active ? '#FFFFFF' : '#007AB1'};
margin: ${({margin}) => margin ? margin : 'auto auto auto 12px'};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
justify-content: center;
align-items: center;
cursor: pointer;
:hover{
  background:#005C87;
  color:#ffff;
}
`;

const AboutSection = styled.div`
  width:100:%
  display:block;
  margin-top: 20px;
  .about-heading{
    color: #005C87;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom:12px;
  }
  .details{
    color: #005C87;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: justify;
    margin: 0px;
    >p{
      margin: 0px;
    }
    >b{
      margin: 0px;
    }
  }
`;

const MuiltiDropdown = styled.div`
  display: flex;
  margin: auto;
  margin-right: 0px;
  .dropdown-menu{
    min-width:100px;
  }
  div{
    .dropdown{
      .sub-menu-wrapper{
        width: 172px;
        display:flex;
        position: absolute;
        z-index:99999;
        right:298%;
        top: 60px
        .leftcard{
         width: 10px;
         display:flex;
        }
        .menuData{
          width: 162px;
          background: white;
          z-index:99999;
          left: -10px;
          top: 50px
          border: 1px solid rgba(0,92,135,0.30);
          border-radius: 6px;
          padding: 0px;
          .sub-menu{
            color: #007AB1;
            font-family: Rubik-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding: 12px 0px 12px 0px;
            cursor:pointer;
            margin-left: 12px;
          }
          hr{
            margin: 0px 12px;
          }
        }
      }
      button{
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-top: ${(isEvent)=> isEvent && '3px'};
        ${'' /* padding-left: ${(isEvent)=> isEvent && '0px'}; */}
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        margin-left: 0px;
        &:active{
          background: none;
          border: none;
        }
      }
      .dropdown-menu.show{
        display: inline-flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        transform: translate3d(-13px, 50px, 0px);
        border-radius: 4px;
        border: 1px solid rgba(0, 92, 135, 0.30);
        background: #FFF;
        display: flex;
        flex-direction: column;
        margin-left: -140px;
        width: 188px !important;
        a{
          color: #007AB1;
          font-family: Rubik-medium;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display:flex;
          padding:8px;
          >svg{
            display:flex;
            margin:auto 0 auto auto;
            transform:rotate(0deg);
            margin-right:8px;
          }
          &:active{
            background: none;
          }
        }
        hr{
          width: 90%;
          color: rgba(0, 92, 135, 0.50);
          margin: 4px 0px;
        }
      }
    }
  }
`;

export { TabsStyle, TabsWrapper, ToolTip, StyledSvg, BuddyTab, AboutSection, MuiltiDropdown}